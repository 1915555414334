import { fetchWrapper } from "@/helpers";

const ruta = process.env.VUE_APP_ROOT_API;

export default class OrdenCompraService {
	async getAllOrdenesCompra() {
		return await fetchWrapper.get(`${ruta}/orden_compra`);
	}

	async guardarOC(oc) {
		return await fetchWrapper.post(`${ruta}/orden_compra`, oc);
	}

	async obtenerOrdenCompra(id) {
		const OcShow = await fetchWrapper.get(`${ruta}/orden_compra/` + id);
		return OcShow;
	}
	async imprimirOC(id) {
		return await fetchWrapper.getPdf(`${ruta}/orden_compra/imprimir/${id}`);
	}
	async anular(id) {
		return await fetchWrapper.delete(`${ruta}/orden_compra/anular/${id}`);
	}

	async filtrarCompras(datos) {
		return await fetchWrapper.post(`${ruta}/filtros_compra`, datos);
	}
  async imprimirPDF(datos) {
    return await fetchWrapper.postPdf(
      `${ruta}/reporte/compra/imprimir`,
      datos
    );
  }
  async exportarCompras(datos) {
    const exportado = await fetchWrapper.postWithBlob(
      `${ruta}/compras-export`,
      datos
    );
    return exportado;
  }

  async getprecio_compra_producto(id) {
    return await fetchWrapper.get(`${ruta}/precio_compra_producto/` + id);
  }

	async inversionNegocio(datos) {
		return await fetchWrapper.post(`${ruta}/reporte_inversion_negocio`, datos);
	}

	async imprimirRInversionPDF(datos) { 
		return await fetchWrapper.postPdf(
			`${ruta}/reporte_inversion_negocio/imprimir`,
			datos
		);
	}
	async exportarInversion(datos) {
		const exportado = await fetchWrapper.postWithBlob(
			`${ruta}/inversion-export`,
			datos
		);
		return exportado;
	}
}
