<template>
  <Toast />
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer">Reportes</a>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active">Compras</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-6 lg:px-8">
    <div class="
          flex
          align-items-center
          flex-column
          lg:justify-content-center lg:flex-row
        ">
      <h4><strong>REPORTE GENERAL DE COMPRAS</strong></h4>
    </div>
    <div class="col-12">
      <div class="card p-fondo">
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-3">
            <label for="sucursales"><strong>SUCURSAL: </strong></label>
            <Dropdown v-model="sucursalSeleccionada" :options="sucursales" optionLabel="nombre" optionValue="id" />
          </div>
          <div class="field col-12 md:col-6">
            <label for=""><strong>NOMBRE PRODUCTO: </strong></label>
            <AutoComplete :dropdown="true" field="descripcion" v-model="productoSelected"
              :suggestions="productosListadoFiltrado" @complete="buscarProducto($event)"
              placeholder="Escriba Nombre Producto">
              <template #item="slotProps">
                <div>
                <strong>{{ slotProps.item.descripcion }}</strong
                ><span
                  ><strong> --- </strong
                  ><!-- </span>
                      {{ slotProps.item.fabricas.nombre_proveedor
                      }}<span><strong> ---</strong> --></span
                >
                {{ slotProps.item.fabrica_nombre
                }}
              </div>
              </template>
            </AutoComplete>
          </div>
          <div class="field col-12 md:col-3">
            <label for="proveedors"><strong>PROVEEDOR: </strong></label>
            <Dropdown v-model="proveedorSeleccionado" :options="proveedors" optionLabel="nombre_completo" optionValue="id"
              :filter="true" />
          </div>
          <div class="field col-12 md:col-3">
            <label for="usuarios"><strong>USUARIOS</strong></label>
            <Dropdown v-model="usuarioSeleccionado" :options="usuarios" optionLabel="name" optionValue="id" />
          </div>
          <div class="field col-12 md:col-3">
            <label for="fecha_compra_desde"><strong>FECHA COMPRA DESDE: </strong></label>
            <Calendar id="fecha_compra_desde" v-model="fecha_compra_desde" dateFormat="dd/mm/yy" :showIcon="true" />
          </div>
          <div class="field col-12 md:col-3">
            <label for="fecha_compra_hasta"><strong>FECHA COMPRA HASTA: </strong></label>
            <Calendar id="fecha_compra_hasta" v-model="fecha_compra_hasta" dateFormat="dd/mm/yy" :showIcon="true" />
          </div>
          <div class="field col-12 md:col-3">
            <label for="acciones"><strong>ACCIONES: </strong></label>
            <Button label="APLICAR FILTROS" icon="pi pi-search" class="p-button-primary mr-2" v-tooltip.top="'Buscar'"
              :loading="enviando" :disabled="enviando" @click="BuscarFiltro" />
          </div>
        </div>
      </div>
      <div class="mt-4">
        <DataTable ref="dtcompras" key="id" :value="compras" responsiveLayout="scroll" :loading="enviando"
          class="p-datatable-sm" :filters="buscar" responsive="true" stripedRows showGridlines :rows="10"
          :paginator="true"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[10, 100, 500, 1000, 5000, 10000]"
          currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} Reportes de Compras">
          <template #header>
            <div class="
                    flex flex-column
                    md:flex-row md:justify-content-end md:align-items-end
                  ">
              <span class="block mt-2 md:mt-0 p-input-icon-left mr-2">
                <i class="pi pi-search" />
                <InputText v-model="buscar['global'].value" placeholder="Buscar Compras..." />
              </span>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <Button v-if="'R_Compras DescargarPDF' in auth.user.permissions" label="Imprimir PDF"
                  icon="pi pi-file-pdf" class="p-button-outlined p-button-danger mr-2"
                  v-tooltip.top="'Imprimir Reporte PDF'" :loading="generandoPDF" :disabled="generandoPDF"
                  @click="generarPDF" />
              </span>
              <span class="block mt-2 md:mt-0 p-input-icon-left mr-2">
                <Button v-if="'R_Compras DescargarEXCEL' in auth.user.permissions" label="Exportar Excel"
                  icon="pi pi-file-excel" class="p-button-outlined p-button-success"
                  v-tooltip.top="'Imprimir Reporte Excel'" :loading="exportando" :disabled="exportando"
                  @click="exportCompras" />
              </span>
            </div>
          </template>
          <template #loading>
            <div class="flex align-items-center justify-content-center">
              <ProgressSpinner />
            </div>
          </template>
          <template #empty>
            <span class="p-invalid">
              Debe aplicar filtros para ver resultados en la tabla!</span>
          </template>
          <Column header="#">
            <template #body="slotProps">
              {{ 1 + slotProps.index }}
            </template>
          </Column>
          <Column field="id" header="CÓDIGO ORDEN" :sortable="true"> </Column>
          <Column field="nombre_sucursal" header="SUCURSAL"> </Column>
          <Column field="nombre_usuario" header="USUARIO"> </Column>
          <Column field="nombre_proveedor" header="PROVEEDOR"> </Column>
          <Column field="fecha_solicitud" header="FECHA SOLICITUD"> </Column>
          <Column field="fecha_recepcion" header="FECHA RECEPCIÓN"> </Column>
          <Column field="nombre_producto" header="DESCRIPCIÓN/PRODUCTOS">
          </Column>
          <Column field="precio" header="PRECIO">
            <template #body="{ data }">
              {{ convertirNumeroGermanicFormat(data.precio) }}
            </template>
          </Column>
          <Column field="cantidad" header="CANTIDAD">
            <template #body="{ data }">
              {{ convertirNumeroGermanicFormat(data.cantidad) }}
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>
<script>
import { FilterMatchMode } from "primevue/api";
import OrdenCompraService from "@/service/OrdenCompraService";
import SucursalService from "@/service/SucursalService";
import ProveedorService from "@/service/ProveedorService";
import UserService from "@/service/UserService";
import ProductService from "@/service/ProductService";
import { useAuth } from "@/stores";

export default {
  data() {
    return {
      sucursales: [{ id: 0, nombre: "TODAS" }],
      sucursalSeleccionada: 0,
      proveedors: [{ id: 0, nombre_completo: "TODOS" }],
      proveedorSeleccionado: 0,
      usuarios: [{ id: 0, name: "TODOS" }],
      usuarioSeleccionado: 0,
      enviando: false,
      fecha_compra_desde: null,
      fecha_compra_hasta: null,
      compras: [],
      generandoPDF: false,
      exportando: false,
      productoSelected: null,
      productosListadoFiltrado: [],
      buscar: {
        global: {
          value: "",
          matchMode: FilterMatchMode.CONTAINS,
        },
      },
    };
  },
  ordenCompraService: null,
  sucursalService: null,
  proveedorService: null,
  UserService: null,
  productService: null,
  auth: null,
  created() {
    this.ordenCompraService = new OrdenCompraService();
    this.sucursalService = new SucursalService();
    this.proveedorService = new ProveedorService();
    this.UserService = new UserService();
    this.productService = new ProductService();
    this.auth = useAuth();
  },
  mounted() {
    this.cargarSucursales();
    this.cargarProveedores();
    this.cargarUsers();
  },
  methods: {
    buscarProducto(event) {
      setTimeout(() => {
        this.productService
          .buscarXNombre({ texto: event.query })
          .then((response) => {
            this.productosListadoFiltrado = [...response.productos];
          })
          .catch((error) => console.log(error));
      }, 250);
    },
    generarPDF() {
      if (this.compras.length > 0) {
        this.generandoPDF = true;
        let datos = {
          sucursal_id: this.sucursalSeleccionada,
          proveedor_id: this.proveedorSeleccionado,
          usuario_id: this.usuarioSeleccionado,
          fecha_compra_desde: this.fecha_compra_desde,
          fecha_compra_hasta: this.fecha_compra_hasta,
          producto_id: this.productoSelected ? this.productoSelected.id : null,
        };
        this.ordenCompraService.imprimirPDF(datos).then(() => {
          this.generandoPDF = false;
          this.$toast.add({
            severity: "success",
            summary: "Éxito",
            detail: "Se generó el PDF correctamente",
            life: 3000,
          });
        });
      } else {
        this.$toast.add({
          severity: "warn",
          summary: "Advertencia",
          detail: "No hay datos para generar el reporte",
          life: 3000,
        });
      }
    },
    exportCompras() {
      if (this.compras.length > 0) {
        this.exportando = true;
        let datos = {
          sucursal_id: this.sucursalSeleccionada,
          proveedor_id: this.proveedorSeleccionado,
          usuario_id: this.usuarioSeleccionado,
          fecha_compra_desde: this.fecha_compra_desde,
          fecha_compra_hasta: this.fecha_compra_hasta,
          producto_id: this.productoSelected ? this.productoSelected.id : null,
        };
        this.ordenCompraService.exportarCompras(datos).then(() => {
          this.exportando = false;
          this.$toast.add({
            severity: "success",
            summary: "Éxito",
            detail: "Se ha generado el archivo correctamente",
            life: 3000,
          });
        });
      } else {
        this.$toast.add({
          severity: "warn",
          summary: "No hay datos para exportar",
          detail: "No hay datos para exportar",
          life: 3000,
        });
      }
    },
    BuscarFiltro() {
      this.compras = [];
      this.enviando = true;
      this.cargarReporteCompras();
    },
    cargarReporteCompras() {
      let datos = {
        sucursal_id: this.sucursalSeleccionada,
        producto_id: this.productoSelected,
        proveedor_id: this.proveedorSeleccionado,
        user_id: this.usuarioSeleccionado,
        fecha_compra_desde: this.fecha_compra_desde,
        fecha_compra_hasta: this.fecha_compra_hasta,
      };
      this.ordenCompraService.filtrarCompras(datos).then((response) => {
        this.compras = response.compras;
        this.enviando = false;
      });
    },
    cargarSucursales() {
      this.sucursalService
        .getSucursalesAll()
        .then((response) => {
          response.forEach((sucursal) => {
            this.sucursales.push(sucursal);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cargarProveedores() {
      this.proveedorService.getProveedores().then((response) => {
        response.proveedores.forEach((proveedor) => {
          this.proveedors.push(proveedor);
        });
      });
    },
    cargarUsers() {
      this.UserService.getUsersAll().then((response) => {
        response.forEach((user) => {
          this.usuarios.push(user);
        });
      });
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE").format(numero);
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";

.p-invalid {
  color: red;
}

.p-fondo {
  background-color: #7bffae;
}
</style>
